<template>
  <div :class="{ 'text-center': center }" class="title-light-main">
    <slot name="default"></slot>
  </div>
</template>

<script>
export default {
  name: "MainTitleLight",
  props: {
    center: {
      type: Boolean,
      default: () => false
    }
  }
};
</script>

<style lang="scss">
.title-light-main {
  font-size: 34px;
  color: theme-color("primary");
  font-weight: 300;
  width: 100%;
  text-transform: uppercase;

  @include media-breakpoint-down("md") {
    font-size: 1.1rem;
  }
}
</style>
