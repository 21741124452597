<template>
  <div
    class="d-flex justify-content-center w-100"
    :class="{ container: withContainer }"
  >
    <div class="small-container mb-2">
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SmallContainer",
  props: {
    withContainer: {
      type: Boolean,
      default: () => false
    }
  }
};
</script>

<style lang="scss">
.small-container {
  min-width: $small-container-min;
  width: $small-container;
}
</style>
