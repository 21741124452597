<template>
  <SmallContainer>
    <div class="title-strip">
      <div>
        <slot name="default"></slot>
      </div>
    </div>
  </SmallContainer>
</template>

<script>
import SmallContainer from "@/components/base/SmallContainer";

export default {
  name: "TitleStrip",
  components: { SmallContainer }
};
</script>

<style lang="scss">
.title-strip {
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
  font-weight: 300;
  font-size: 23px;
  text-transform: lowercase;

  &::before {
    position: absolute;
    content: "";
    top: 50%;
    bottom: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: theme-color("secondary");
    z-index: 0;
  }

  & > div {
    display: flex;
    background: $body-bg;
    z-index: 1;
    padding: 0 22px;
  }

  @include media-breakpoint-down("md") {
    font-size: 1rem;

    & > div {
      padding: 0 11px;
    }
  }
}
</style>
