<template>
  <div class="home">
    <div class="d-flex justify-content-center flex-column align-items-center">
      <filled-button
        class="btn-wide mb-4"
        color="primary"
        :to="{ name: 'EventNew' }"
        v-if="auth && user && user.admin"
      >
        {{ $t("event.create.title") }}
      </filled-button>
      <base-input
        v-if="eventsExists"
        class="mb-4"
        v-model="searchInput"
        :label="$t('search')"
      ></base-input>
    </div>
    <div v-if="events && events.length">
      <template v-if="futureEvents && futureEvents.length">
        <EventListYear :items="futureEvents" />
      </template>
      <template v-if="pastEvents && pastEvents.length">
        <MainTitleBold center>{{ $t("event.list.past") }}</MainTitleBold>
        <EventListYear :items="pastEvents" past :year="lastFutureEventYear" />
      </template>
    </div>
    <div class="no-events-whitespace" v-else-if="!additionalLoading">
      <MainTitleLight center>
        {{ $t("event.list.empty") }}
      </MainTitleLight>
    </div>
    <div class="search-whitespace" v-if="additionalLoading">
      <TitleStrip center> {{ $t("loading") }}... </TitleStrip>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import MainTitleBold from "../components/base/MainTitleBold";
import FilledButton from "../components/buttons/FilledButton";
import { mapGetters } from "vuex";
import EventListYear from "@/components/events/EventListYear";
import API from "@/common/api/api.functions";
import loadingMixin from "../mixins/loadingMixin";
import MainTitleLight from "@/components/base/MainTitleLight";
import TitleStrip from "@/components/base/TitleStrip";
import BaseInput from "@/components/inputs/BaseInput";
import { debounce } from "lodash";

export default {
  name: "Home",
  components: {
    BaseInput,
    TitleStrip,
    MainTitleLight,
    EventListYear,
    FilledButton,
    MainTitleBold
  },
  mixins: [loadingMixin],
  data: () => ({
    nowDate: new Date(),
    events: [],
    eventsExists: false,
    pageNext: 1,
    additionalLoading: false,
    searchInput: null
  }),
  methods: {
    getMaxDate(event) {
      const dateEvent = event.dateEnd
        ? new Date(event.dateEnd)
        : new Date(event.dateStart);
      dateEvent.setHours(23, 59, 59999);
      return dateEvent;
    },
    async onScrollEvents(e) {
      let events = e.target.scrollingElement;
      if (events) {
        if (
          events.scrollHeight - events.clientHeight - events.scrollTop <
          400
        ) {
          await this.fetchEvents();
        }
      }
    },
    debounceFetchEvents: debounce(async function() {
      await this.fetchEvents();
    }, 1500),
    async fetchEvents() {
      if (!this.pageNext) return;
      if (this.additionalLoading && this.pageNext !== 1) return;
      this.additionalLoading = true;
      let eventList = await API.getEventList(this.pageNext, this.searchInput)
        .then(res => res.data)
        .catch(e => {
          e.response?.data
            ? this.$toast.error(e.response.data)
            : this.$toast.error("Неизвестная ошибка загрузки мероприятий");
        });
      if (eventList) {
        this.events = [
          ...this.events,
          ...eventList.results.map(val => ({
            ...val,
            dateStart: val.date_begin_event,
            dateEnd: val.date_end_event
          }))
        ];
        if (eventList.next) {
          this.pageNext++;
        } else this.pageNext = null;
      }
      setTimeout(() => (this.additionalLoading = false), 100);
    }
  },
  computed: {
    ...mapGetters(["auth", "jwt", "user"]),
    futureEvents() {
      return this.events.filter(
        val => this.getMaxDate(val).getTime() >= this.nowDate.getTime()
      );
    },
    pastEvents() {
      return this.events.filter(
        val => this.getMaxDate(val).getTime() < this.nowDate.getTime()
      );
    },
    lastFutureEventYear() {
      const futureEvents = this.futureEvents;
      const lastEvent = futureEvents[futureEvents.length - 1];
      if (!lastEvent) return;
      return new Date(lastEvent.dateStart).getFullYear();
    }
  },
  async mounted() {
    this.loading++;
    await this.fetchEvents();
    if (this.events && this.events.length) this.eventsExists = true;
    this.loading--;
    window.document.addEventListener("scroll", this.onScrollEvents, false);
  },
  beforeDestroy() {
    window.document.removeEventListener("scroll", this.onScrollEvents, false);
  },
  watch: {
    searchInput() {
      this.additionalLoading = true;
      this.events = [];
      this.pageNext = 1;
      this.debounceFetchEvents();
    }
  }
};
</script>

<style lang="scss">
.no-events-whitespace {
  min-height: 400px;
}
</style>
