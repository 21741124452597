<template>
  <div>
    <template v-for="(year, key) in filteredByYear">
      <MainTitleLight center v-if="year.year" :key="`title_${key}`">
        {{ year.year }}
      </MainTitleLight>
      <EventList :items="year.items" :past="past" :key="`event_${key}`" />
    </template>
  </div>
</template>

<script>
import EventList from "./EventList";
import MainTitleLight from "../base/MainTitleLight";
export default {
  name: "EventListYear",
  props: {
    items: {
      type: Array,
      default: () => []
    },
    past: {
      type: Boolean,
      default: () => false
    },
    year: {
      type: Number,
      default: () => new Date().getFullYear()
    }
  },
  components: {
    EventList,
    MainTitleLight
  },
  computed: {
    filteredByYear() {
      const years = {};
      this.items.forEach(val => {
        let year = new Date(val.dateStart).getFullYear();
        years[year] = [...(years[year] || []), val];
      });
      const yearsArray = [];
      Object.entries(years).forEach(val => {
        yearsArray.push({
          year: val[0],
          items: val[1]
        });
      });
      yearsArray.sort((a, b) => b.year - a.year);
      if (yearsArray[0].year.toString() === this.year.toString()) {
        yearsArray[0].year = "";
      }
      return yearsArray;
    }
  }
};
</script>
