<template>
  <router-link
    :is="withoutLink ? 'span' : 'router-link'"
    :to="
      withoutLink ? null : { name: 'EventDetails', params: { slug: item.slug } }
    "
    class="event-short-info-link"
  >
    <div class="event-short-info">
      <div class="img-short">
        <div
          v-if="showImage"
          v-lazy-container="{
            selector: 'img',
            error: '/static_images/loading.svg',
            loading: '/static_images/loading.svg'
          }"
        >
          <img :data-src="image" :alt="item.title" />
        </div>
        <div class="dates date-start">
          <div class="dates-inner" v-if="dateStart">
            <div>
              {{ dateStart }}
            </div>
          </div>
        </div>
        <div class="dates date-end">
          <div class="dates-inner" v-if="dateEnd && dateStart !== dateEnd">
            <div>
              {{ dateEnd }}
            </div>
          </div>
        </div>
      </div>
      <div class="title">{{ item.title }}</div>
      <div
        class="text-center opacity-50"
        v-if="item.only_link_access || item.draft"
      >
        <span
          v-if="item.only_link_access"
          class="badge bg-secondary text-light mx-1"
          >{{ $t("event.short.onlyLink") }}</span
        >
        <span v-if="item.draft" class="badge bg-secondary text-light mx-1">{{
          $t("event.short.draft")
        }}</span>
      </div>
    </div>
  </router-link>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EventShortInfo",
  props: {
    item: {
      type: Object,
      required: true
    },
    withoutLink: Boolean
  },
  data: () => ({
    locale: "ru-RU",
    options: {
      month: "long",
      day: "numeric"
    },
    showImage: true,
    loaded: false
  }),
  computed: {
    ...mapGetters(["language"]),
    image() {
      return this.item.image;
    },
    dateStart() {
      return this.item?.dateStart
        ? new Date(this.item.dateStart).toLocaleDateString(
            this.language,
            this.options
          )
        : null;
    },
    dateEnd() {
      return this.item?.dateEnd
        ? new Date(this.item.dateEnd).toLocaleDateString(
            this.language,
            this.options
          )
        : null;
    }
  },
  watch: {
    image() {
      this.showImage = false;
      this.$nextTick(() => (this.showImage = true));
    }
  }
};
</script>

<style lang="scss">
.event-short-info-link {
  text-decoration: none;
}

.event-short-info {
  margin-bottom: 28px;

  & > .img-short {
    overflow: hidden;
    background: theme-color("light");
    position: relative;
    margin-bottom: 24px;
    height: 0;
    padding-top: calc(518 / 676 * 100%);

    * {
      margin: 0;
    }

    img,
    svg {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center;

      &[lazy="loaded"] {
        background-color: #000;
      }
    }

    .dates {
      position: absolute;

      &.date {
        &-start {
          top: 0;
          left: 0;

          &::before {
            left: 0;
            margin-left: -33%;
            background: theme-color("success");
          }
        }

        &-end {
          bottom: 0;
          right: 0;

          &::before {
            left: 0;
            margin-left: -5%;
            background: theme-color("danger");
          }
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 140%;
        transform: skew(-45deg);
      }

      .dates-inner {
        position: relative;
        padding: 10px 16px;
        color: theme-color("light");
        font-size: 26px;
        font-weight: bold;

        @include media-breakpoint-down("md") {
          font-size: 1.2rem;
        }
      }
    }
  }

  & > .title {
    font-weight: bold;
    font-size: 20px;
    width: 100%;
    text-align: center;
    //color: $secondary;
    text-decoration: none;

    //&:hover {
    //  color: hard-dark($secondary);
    //}
    @include media-breakpoint-down("md") {
      font-size: 1.1rem;
    }
  }
}
</style>
