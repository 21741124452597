<template>
  <div
    class="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-0 g-md-2 mt-1 justify-content-center"
    :class="{ 'past-events': past }"
  >
    <EventShortInfo
      class="col"
      :item="item"
      v-for="item in items"
      :key="item.slug"
    />
  </div>
</template>

<script>
import EventShortInfo from "./EventShortInfo";
export default {
  name: "EventList",
  components: { EventShortInfo },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    past: {
      type: Boolean,
      default: () => false
    }
  }
};
</script>

<style lang="scss">
.past-events .event-short-info {
  opacity: 1;
  transition: all 0.3s;
}

.past-events .event-short-info:not(:hover):not(:active) {
  opacity: 0.55;
}
</style>
